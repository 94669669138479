<template>
  <div class="dashboard">
    <div class="Greeting">
      <h2 class="fw-large blue-dark mb-3 fs-10">
        Hello, {{ getAuthenticatedUser | getUserFullName }}
      </h2>
      <el-button
        style="float: right; margin-right: 10px"
        size="mini"
        @click="printPDF"
        v-if="hasCustomDashboard && activeCustomDashboard"
        ><el-tooltip content="Print as PDF"
          ><i class="el-icon-download"></i></el-tooltip
      ></el-button>
      <el-button
        style="float: right; margin-right: 10px"
        type="primary"
        size="mini"
        round
        @click="exportToExcel"
        v-if="
          hasCustomDashboard &&
          activeCustomDashboard &&
          checkIntegrationDashboard()
        "
        ><i class="el-icon-upload2"></i> Export as Excel</el-button
      >
    </div>
    <div
      id="captureArea"
      v-loading="loading"
      element-loading-text="Please wait, we are getting your dashboard ready."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div v-if="hasCustomDashboard && activeCustomDashboard">
        <draggable
          v-model="customDashboardsList"
          group="dashboards"
          @end="onDragEnd"
          class="dashboard-cards"
        >
          <el-card
            v-for="(item, index) in customDashboardsList"
            :key="index + item._id"
            class="dashboard-card"
            :class="{ active: activeCustomDashboardId === item._id }"
            @click.native="onCardClick(index, item._id)"
          >
            <div>{{ item.name }}</div>
          </el-card>
        </draggable>
        <!-- <el-tabs
          v-model="activeCustomDashboardId"
          @tab-click="changeDashboard"
          type="card"
        >
          <el-tab-pane
            v-for="(item, index) in customDashboardsList"
            :key="index + item._id"
            :label="item.name"
            :name="item._id"
          ></el-tab-pane>
        </el-tabs> -->
        <el-row class="dashboard-filters" v-if="checkHasFilter()">
          <el-col :span="24" style="border-bottom: #d2dcfa solid 1px">
            <CustomDashboardFilters
              v-if="dateFilter"
              :getAllEntities="getAllEntities"
              :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
              :allDashboardstats="activeCustomDashboard"
              :integrations="integrations"
              :predefinedIntegrations="predefinedIntegrations"
              @updateGlobalDateFilterValue="updateGlobalDateFilterValue"
            ></CustomDashboardFilters>
          </el-col>
        </el-row>
        <el-row
          v-if="
            activeCustomDashboard &&
            activeCustomDashboard.allStatComponents &&
            activeCustomDashboard.allStatComponents.length
          "
        >
          <el-col :span="24">
            <CustomDashboardStats
              :getAllEntities="getAllEntities"
              :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
              :allDashboardstats="activeCustomDashboard"
              :hide_options="true"
              :refresh="refresh"
              :integrations="integrations"
              :predefinedIntegrations="predefinedIntegrations"
              @DateFilterUpdate="DateFilterUpdate"
              @catchIntegrationTableInfo="setIntegrationTableInfo"
              @captureLatestStats="handleChildEvent"
              @updateCsutomDashboard="updateCsutomDashboard"
              :isIntegrationEdit="true"
            ></CustomDashboardStats>
          </el-col>
        </el-row>
      </div>
      <div
        class="stats-container"
        v-loading="statsLoading"
        element-loading-text="Loading..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        v-else
      >
        <el-scrollbar>
          <div class="mb-1" v-if="activeDashboardStatics">
            <el-row>
              <el-col
                :lg="3"
                :md="6"
                :sm="12"
                :xs="12"
                class="card dashboard-top-buttons"
                style="min-with: 100px"
                v-for="(stat, index) in activeDashboardStatics"
                :key="index"
                :style="
                  isSelectedStats(stat.slug)
                    ? `color:white;background-color:${stat.active_color}`
                    : `background-color:${stat.background_color}`
                "
              >
                <div
                  style="width: 150px"
                  class="p-1"
                  v-on:click="getSelecetedDocumentStatus(stat)"
                >
                  <div class="d-flex align-items-center mb-3">
                    <p class="Title_sty mb-0 fw-300 pl-1">{{ stat.title }}</p>
                  </div>
                  <div class="d-flex align-items-center">
                    <img
                      class="imgstyle"
                      :src="
                        require(`@/assets/img/icons/${
                          isSelectedStats(stat.slug)
                            ? 'dashboardActiveIcons'
                            : 'dashboardStatsIcons'
                        }/${stat.key}.svg`)
                      "
                      alt="Docs"
                    />
                  </div>
                  <div
                    class="number_sty count fs-6 fw-light"
                    :style="
                      isSelectedStats(stat.slug) ? `color:white` : `color:black`
                    "
                  >
                    {{ stat.count }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
      <el-row v-if="hasCustomDashboard && activeCustomDashboard">
        <div
          v-if="
            activeCustomDashboard.allTableComponents &&
            activeCustomDashboard.allTableComponents.length
          "
        >
          <div class="wrapper-grid">
            <draggable
              class="drag-grid"
              @end="switchPositions"
              :disabled="!activeCustomDashboard.allowUserChangeOrder"
            >
              <div
                v-for="(
                  component, index
                ) of activeCustomDashboard.allTableComponents"
                :key="
                  component.entity_id +
                  '_' +
                  component.keyValue +
                  '_table_' +
                  index
                "
                class="grid-item"
                :style="
                  expendedIndexs.indexOf(index) != -1 ||
                  getIsMobile ||
                  component.type == 'SCHEDULING'
                    ? 'grid-column: 1/3;'
                    : ''
                "
              >
                <CustomDashboardTable
                  v-if="component.type == 'TABLE'"
                  :component="component"
                  :entity_id="component.entity_id"
                  :filter_id="component.filter_id"
                  :index="index"
                  :globalVariablesData="globalVariablesData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                  @expendTable="expendTable"
                  :hide_options="true"
                ></CustomDashboardTable>
                <CustomDashboardPieChart
                  v-else-if="component.type == 'CHART'"
                  :component="component"
                  :index="index"
                  @expendTable="expendTable"
                  :hide_options="true"
                  :globalVariablesData="globalVariablesData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                  :integrations="integrations"
                  :predefinedIntegrations="predefinedIntegrations"
                  @updateCsutomDashboard="updateCsutomDashboard"
                  :isIntegrationEdit="true"
                  :globalDateValue="globalDateValue"
                ></CustomDashboardPieChart>
                <CustomDashboardCalendar
                  v-else-if="component.type == 'CALENDAR'"
                  :component="component"
                  :index="index"
                  @expendTable="expendTable"
                  :hide_options="true"
                  :globalVariablesData="globalVariablesData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                >
                </CustomDashboardCalendar>
                <CustomDashboardLogins
                  v-else-if="component.type == 'LOGINS'"
                  :component="component"
                  :index="index"
                  @expendTable="expendTable"
                  :hide_options="true"
                  :globalVariablesData="globalVariablesData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                >
                </CustomDashboardLogins>
                <EntityViews
                  v-else-if="component.type == 'ENTITY_VIEW'"
                  :entityViewId="component.entity_view_id"
                  :fromCustomDashboard="true"
                  :index="index"
                  :component="component"
                  :hide_options="true"
                  @expendTable="expendTable"
                  :globalVariablesData="globalVariablesData"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
                >
                </EntityViews>
                <CustomDashboardGaugeChart
                  v-else-if="component.type == 'GAUGE_CHART'"
                  :component="component"
                  :index="index"
                  :hide_options="true"
                  @expendTable="expendTable"
                >
                </CustomDashboardGaugeChart>
                <CustomDashboardNotifications
                  v-else-if="component.type == 'NOTIFICATIONS'"
                  :component="component"
                  :index="index"
                  :hide_options="true"
                >
                </CustomDashboardNotifications>
                <SchedulingEntity
                  v-else-if="component.type == 'SCHEDULING'"
                  :getAllEntities="getAllEntities"
                  :getAllCompanyTemplatesData="
                    getAllCompanyTemplatesData.data || []
                  "
                  :component="component"
                  :index="index"
                  :hide_options="true"
                ></SchedulingEntity>
              </div>
            </draggable>
          </div>
        </div>
        <div v-else-if="!hasDataTableStat">
          <el-empty description="No tables configured"></el-empty>
        </div>
        <!-- :span="index == expendedIndex || getIsMobile ? 24 : 12" -->
      </el-row>
      <el-row v-else>
        <el-col :xl="12" :lg="10" :md="8" :sm="12" :xs="12">
          <!-- <div class="d-flex justify-content-between align-items-center"> -->
          <h3 class="fw-medium blue-dark mb-3 fs-6">
            {{ `${getSelecetedDocStatus}` }}
          </h3>
        </el-col>
        <el-col
          v-if="
            !getIsMobile &&
            this.$route &&
            this.$route.query &&
            this.$route.query.type &&
            this.$route.query.type == 'company-documents' &&
            checkPermissionByPermissionName('viewDocument')
          "
          :xl="8"
          :lg="8"
          :md="8"
          :sm="12"
          :xs="12"
        >
          <el-select
            v-model="selected_company_user"
            filterable
            size="small"
            clearable
            class="select float-right mr-3 select-company_type"
            placeholder="Select Company User"
          >
            <el-option
              v-for="(status, index) in companyUsers"
              :key="index"
              :value="status.first_name + ' ' + status.last_name"
              :label="status.first_name + ' ' + status.last_name"
            ></el-option>
          </el-select>
        </el-col>
        <el-col
          v-if="
            !getIsMobile &&
            this.$route &&
            this.$route.query &&
            this.$route.query.type &&
            this.$route.query.type == 'company-documents'
          "
          :xl="4"
          :lg="4"
          :md="8"
          :sm="12"
          :xs="12"
        >
          <el-input
            class="search float-right mb-3 icon-search"
            placeholder="Search"
            v-model="search_string"
            clearable
          >
            <i class="el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col
          class="search float-right mr-2"
          v-else
          :xl="6"
          :lg="4"
          :md="12"
          :sm="12"
          :xs="12"
        >
          <el-tooltip placement="top" :hide-after="2000">
            <div slot="content">
              You can search by first name, last name,email, and<br />address
              that are present in the document users.
            </div>
            <el-input
              placeholder="Search"
              prefix-icon="el-icon-search"
              size="small"
              v-model="search_string"
              clearable
            >
            </el-input>
          </el-tooltip>
        </el-col>
        <!-- </div> -->
        <el-col :xl="24" :lg="24" :md="24" :sm="24" :xs="24" v-if="dataChecked">
          <DocumentsList
            page_size="5"
            :selectedDocStatus="selecetedDocStatus"
            :selected_company_user="selected_company_user"
            :search_string="search_string"
            @reloadDashboardStats="getDashboardStats"
          ></DocumentsList>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DocumentsList from "@/components/companyDocuments/DocumentsList";
import PermissionsHelper from "@/mixins/permissionsHelper";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import draggable from "vuedraggable";
import { fetchGlobalVariables } from "../repo/globalVariables";
import { fetchAssignedCustomDashboards } from "../repo/customDashboardRepo";
import { fetchEntitiesByPagination } from "../repo/entityRepo";
import { getAllCompanyTemplatesByPagination } from "../repo/templatesRepo";
import { postAPICall } from "@/helpers/httpHelper";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "@/config/axios";
import { updateCustomDashboardData } from "@/repo/customDashboardRepo";
import moment from "moment";
// import config from "../config/app";
export default {
  name: "Dashboard",
  metaInfo: {
    title: "Dashboard",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "eSigns understands your business process better, faster, and quicker than the rest of the world to ensure hassle-free and paperless workflow management.",
      },
    ],
  },
  props: ["pageSizes", "type", "categoryId", "groupId"],
  mixins: [PermissionsHelper, MobileRelatedHelper],
  components: {
    DocumentsList,
    CustomDashboardTable: () =>
      import("@/components/customDashboard/customDashboardTable.vue"),
    CustomDashboardStats: () =>
      import("@/components/customDashboard/customDashboardStats.vue"),
    CustomDashboardPieChart: () =>
      import("@/components/customDashboard/customDashboardPieChart.vue"),
    CustomDashboardFilters: () =>
      import("@/components/customDashboard/customDashboardFilters.vue"),
    CustomDashboardCalendar: () =>
      import("@/components/customDashboard/customDashboardCalendar.vue"),
    CustomDashboardLogins: () =>
      import("@/components/customDashboard/customDashboardLogins.vue"),
    draggable,
    EntityViews: () => import("@/components/entity/EntityViews.vue"),
    CustomDashboardGaugeChart: () =>
      import("@/components/customDashboard/customDashboardGaugeChart.vue"),
    CustomDashboardNotifications: () =>
      import("@/components/customDashboard/customDashboardNotifications.vue"),
    SchedulingEntity: () =>
      import("@/components/customDashboard/schedulerComponent.vue"),
  },
  data() {
    return {
      dragging: false,
      statsLoading: false,
      selecetedDocStatus: "",
      search_string: "",
      selected_company_user: "",
      selectedDocTitle: "",
      companyUsers: [],
      activeDashboardStatics: [],
      DashboardStatsColor: [
        {
          key: "all_documents",
          background_color: "#FFDDE9",
          active_color: "#FF679D",
        },
        {
          key: "waiting_for_me",
          background_color: "#FFEEDE",
          active_color: "#FFA754",
        },
        {
          key: "waiting_for_others",
          background_color: "#FFFBD9",
          active_color: "#F2DD27",
        },
        {
          key: "approved",
          background_color: "#EDFCF6",
          active_color: "#52E5A4",
        },
        {
          key: "sent_docs",
          background_color: "#F0F4FF",
          active_color: "#668FFF",
        },
        {
          key: "draft_docs",
          background_color: "#F6F1E6",
          active_color: "#C9A362",
        },
        {
          key: "expired",
          background_color: "#FFE0E0",
          active_color: "#F37F7F",
        },
        {
          key: "expiring_soon",
          background_color: "#E4F5FC",
          active_color: "#6AD6FF",
        },
        { key: "voided", background_color: "#F2F1FB", active_color: "#8370D6" },
        {
          key: "declined",
          background_color: "#FFE8FF",
          active_color: "#FF85FF",
        },
        {
          key: "deleted",
          background_color: "#FFE8E8",
          active_color: "#FF8585",
        },
        {
          key: "completed",
          background_color: "#E8FFE8",
          active_color: "#85FF85",
        },
        {
          key: "company-documents",
          background_color: "#d4e6fc",
          active_color: "#245fa6",
        },
      ],
      selectedRoute: "",
      customDashboardsList: [],
      hasCustomDashboard: false,
      activeCustomDashboard: null,
      activeCustomDashboardId: "",
      expendedIndexs: [],
      refresh: false,
      globalVariablesData: [],
      loading: false,
      dataChecked: false,
      getAllEntities: null,
      getAllCompanyTemplatesData: null,
      integrations: [],
      predefinedIntegrations: [],
      dateFilter: false,
      selectedGlobalDateFilter: null,
      integrationComponent: null,
      formattedStats: null,
      globalDateValue: {
        startDate: "",
        endDate: "",
      },
    };
  },
  computed: {
    getSelecetedDocStatus() {
      if (this.selecetedDocStatus === "waiting-for-me") {
        return "Documents Waiting for Me";
      }
      if (this.selecetedDocStatus === "waiting-for-others") {
        return "Documents Waiting for Others";
      }
      if (this.selecetedDocStatus === "all-documents") {
        return this.selectedDocTitle;
      }
      return this.selectedDocTitle + " " + "Documents";
    },
    ...mapGetters("paymentGateway", ["getPaymentsHistory", "getPlansFeatures"]),
    ...mapGetters("dashboard", ["getAllDocumentsDashboardStats"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("settings", ["getUserDashboardStastics"]),
    ...mapGetters("documents", ["getAllCompanyUsers"]),
    ...mapGetters("customDashboard", [
      "getUpdateDashboardStatus",
      "getUpdateDashboardData",
      "getUpdateDashboardError",
    ]),
    // ...mapGetters("customDashboard", ["getUserDashboardsList"]),
    // ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
  },
  mounted() {
    this.loading = true;
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.getAuthenticatedUser?.activeRole?.userType_id) {
      this.fetchDashboardList();
    } else {
      this.getDashboardStats();
    }
  },
  methods: {
    ...mapActions({
      fetchNotifications: "dashboard/fetchNotifications",
      fetchUserDashboardStatics: "settings/fetchUserDashboardStatics",
      fetchAllDocumentsDashboardStats:
        "dashboard/fetchAllDocumentsDashboardStats",
    }),
    async printPDF() {
      this.$message("Please wait, we are generating your pdf...");
      const element = document.getElementById("captureArea");
      try {
        const canvas = await html2canvas(element, { scale: 2 });
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;
        var doc = new jsPDF("p", "mm", "a4");
        var position = 0;
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${this.activeCustomDashboard.name}.pdf`);
      } catch (error) {
        this.$message.error("PDF generation failed");
        console.error("PDF generation failed:", error);
      }
    },
    async exportToExcel() {
      try {
        let tableSection = {};
        let statInfo = {};
        if (this.formattedStats.length) {
          this.formattedStats.forEach((stat) => {
            statInfo[stat.component_name] = stat.count;
          });
        }
        let statCounts = {
          data: [statInfo],
          sectionName: "All Stats",
          type: "total",
        };
        let macthedComponent = this.formattedStats?.find(
          (stat) => stat.keyValue === this.integrationComponent?.keyValue
        );
        if (macthedComponent) {
          let integrationFilters =
            this.activeCustomDashboard.allIntegrationFilters;
          let mactchedIntegration = this.integrations?.find(
            (int) => int._id == macthedComponent.integration_id
          );
          let dependencies = {};
          if (integrationFilters && integrationFilters.length) {
            integrationFilters.map((filter) => {
              dependencies[filter["dependency"]] = filter["default_value"];
            });
          } else {
            dependencies = mactchedIntegration.dependencies;
          }
          tableSection = {
            provider: mactchedIntegration
              ? mactchedIntegration.application
              : "",
            parameters: dependencies,
            fields: macthedComponent.selectedColumns,
            asset: macthedComponent.asset,
            sectionName: macthedComponent.component_name,
            type: "multiple",
          };
          if (macthedComponent.apply_date_filter) {
            tableSection["dateFilter"] = {
              startDate: macthedComponent.dates.startDate,
              endDate: macthedComponent.dates.endDate,
            };
          }
        }
        let payload = {
          sections: [statCounts],
        };
        if (tableSection && Object.keys(tableSection).length > 0) {
          payload["sections"].push(tableSection);
        }
        this.$message("Please wait, exporting to excel...");
        let response = await axios.post(
          `/export-custom-dashboard-to-excel/${this.activeCustomDashboardId}`,
          payload
        );
        if (response.data && response.data.status) {
          let fileURL = response.data.data;
          const buffer = new Uint8Array(fileURL.data);
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          fileURL = window.URL.createObjectURL(blob);
          link.href = fileURL;
          link.download = `${this.activeCustomDashboard.name}.xlsx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.$notify.success({
            title: "Success",
            message: response.data.message,
          });
        } else {
          this.$notify.error({
            title: "Error",
            message: response.data.message,
          });
        }
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err.message
            ? err.message
            : "failed to export try again after some time",
        });
      }
    },
    switchPositions(evt) {
      if (
        evt &&
        typeof evt.oldIndex == "number" &&
        typeof evt.newIndex == "number" &&
        this.activeCustomDashboard.allTableComponents[evt.oldIndex]
      ) {
        let data = this.activeCustomDashboard.allTableComponents[evt.oldIndex];
        let data1 = this.activeCustomDashboard.allTableComponents[evt.newIndex];
        data.order = evt.newIndex;
        data1.order = evt.oldIndex;
        this.activeCustomDashboard.allTableComponents[evt.oldIndex] = {
          ...data,
        };
        this.activeCustomDashboard.allTableComponents[evt.newIndex] = {
          ...data1,
        };
      }
    },
    // changeDashboard(tab) {
    //   this.refresh = true;
    //   this.dateFilter = false;
    //   this.changeCustomDashboard(tab.index);
    // },

    onCardClick(index, id) {
      if (this.dragging) return;
      this.activeCustomDashboardId = id;
      this.refresh = true;
      this.dateFilter = false;
      this.changeCustomDashboard(index);
    },
    checkHasFilter() {
      return (
        this.activeCustomDashboard.allCustomFilters?.length ||
        (this.activeCustomDashboard.includeGlobalDateFilter &&
          this.activeCustomDashboard.allStatComponents?.length) ||
        this.activeCustomDashboard.allMulCustomFilters?.length ||
        this.activeCustomDashboard.allIntegrationFilters?.length
      );
    },
    onDragEnd() {
      this.customDashboardsList;
    },
    expendTable(index) {
      if (this.expendedIndexs.indexOf(index) == -1) {
        this.activeCustomDashboard.allTableComponents[index][
          "is_expand"
        ] = true;
        this.expendedIndexs.push(index);
      } else {
        this.expendedIndexs.splice(this.expendedIndexs.indexOf(index), 1);
        this.activeCustomDashboard.allTableComponents[index][
          "is_expand"
        ] = false;
      }
    },
    async fetchDashboardList() {
      try {
        const [
          getUserDashboardsList,
          getAllGlobalVariables,
          integrations,
          predefinedIntegrations,
        ] = await Promise.all([
          fetchAssignedCustomDashboards(
            this.getAuthenticatedUser.activeRole.userType_id
          ),
          fetchGlobalVariables({ get_all: true }),
          postAPICall("GET", "integrations", { get_all: true }),
          postAPICall("GET", "integrations/service/get-all-applications"),
        ]);

        // Assign fetched data to component properties
        this.globalVariablesData = getAllGlobalVariables?.data || [];
        this.integrations = integrations?.data || [];
        this.predefinedIntegrations = predefinedIntegrations?.data || [];

        if (!getUserDashboardsList?.length) {
          this.getDashboardStats();
          this.loading = false;
          return;
        }

        // Filter dashboards that are visible and contain components
        this.customDashboardsList = getUserDashboardsList.filter(
          (dashboard) =>
            dashboard.components.length && dashboard.main_dashboard_visible
        );

        let template_ids = new Set(),
          fieldKeys = new Set(),
          entity_ids = new Set();

        this.customDashboardsList.forEach(({ components }) => {
          components.forEach((component) => {
            const {
              type,
              filter_field,
              selectedCalendarEntities,
              calenderDateField,
              job_entity,
              job_entity_time_field,
              resource_entity,
              scheduler_entity,
              scheduler_entity_date_field,
              other_entity_date,
              start_date,
              frequency,
              columns,
              other_entity_id,
            } = component;

            if (type === "CUSTOM_FILTER" && filter_field?.includes("#")) {
              const [templateId, fieldKey] = filter_field.split("#");
              template_ids.add(templateId);
              fieldKeys.add(fieldKey);
            }

            if (type === "CALENDAR") {
              entity_ids = new Set([
                ...entity_ids,
                ...selectedCalendarEntities,
              ]);
              (calenderDateField || []).forEach(({ field }) => {
                if (field?.includes("#")) {
                  const fieldKey = field.split("#")[1];
                  template_ids.add(fieldKey);
                  fieldKeys.add(fieldKey);
                }
              });
            }

            if (type === "SCHEDULING") {
              [
                job_entity,
                job_entity_time_field,
                resource_entity,
                scheduler_entity_date_field,
              ]
                .filter(Boolean)
                .forEach((field) => {
                  if (field.includes("#")) {
                    const [templateId] = field.split("#");
                    template_ids.add(templateId);
                  }
                });

              entity_ids.add(scheduler_entity);
            }

            if (type === "STAT" && component.display_type === "MISSING_SLOTS") {
              [other_entity_date, start_date, frequency]
                .filter(Boolean)
                .forEach((field) => {
                  if (field.includes("#")) {
                    const [templateId, fieldKey] = field.split("#");
                    template_ids.add(templateId);
                    fieldKeys.add(fieldKey);
                  }
                });

              columns?.forEach((col) => {
                if (col.includes("#")) {
                  const [templateId, fieldKey] = col.split("#");
                  template_ids.add(templateId);
                  fieldKeys.add(fieldKey);
                }
              });

              if (other_entity_id?.includes("#")) {
                entity_ids.add(other_entity_id.split("#")[0]);
              }
            }
          });
        });

        // Prepare API calls for fetching templates and entities
        let promises = {};
        if (template_ids.size) {
          promises.templates = getAllCompanyTemplatesByPagination({
            get_all: true,
            include_standard: true,
            // include_templates: Array.from(template_ids),
            // field_keys: Array.from(fieldKeys),
          });
        }
        if (entity_ids.size) {
          promises.entities = fetchEntitiesByPagination({
            existed_entity_ids: Array.from(entity_ids),
            get_all: true,
          });
        }

        const results = await Promise.all(Object.values(promises));

        if (promises.templates) {
          this.getAllCompanyTemplatesData =
            results[Object.keys(promises).indexOf("templates")];
        }
        if (promises.entities) {
          this.getAllEntities =
            results[Object.keys(promises).indexOf("entities")];
        }

        if (this.customDashboardsList.length) {
          this.changeCustomDashboard(0);
          this.hasCustomDashboard = true;
        } else {
          this.getDashboardStats();
        }
      } catch (error) {
        console.error("Error fetching dashboard list:", error);
      } finally {
        this.loading = false;
      }
    },
    changeCustomDashboard(index) {
      this.activeCustomDashboard = null;
      let allMainComponents = this.customDashboardsList[
        index
      ].components.filter((e) =>
        [
          "TABLE",
          "CHART",
          "CALENDAR",
          "LOGINS",
          "ENTITY_VIEW",
          "GAUGE_CHART",
          "NOTIFICATIONS",
          "SCHEDULING",
        ].includes(e.type)
      );
      allMainComponents = allMainComponents.filter((e) => {
        if (e.type == "TABLE" && (!e.entity_id || !e.entity_id.length)) {
          return false;
        }
        return true;
      });
      this.activeCustomDashboard = {
        name: this.customDashboardsList[index].name,
        includeGlobalDateFilter:
          this.customDashboardsList[index].include_global_date_filter,
        date_value: this.customDashboardsList[index].date_value,
        Custom_date_value: this.customDashboardsList[index].Custom_date_value,
        allTableComponents: allMainComponents.sort((a, b) =>
          a.order < b.order ? -1 : 1
        ),
        allStatComponents: this.customDashboardsList[index].components
          .filter((e) => e.type == "STAT")
          .map((e) => {
            if (e?.data_source == "INTEGRATIONS") {
              e.dates = {
                startDate: "",
                endDate: "",
              };
            }
            return e;
          }),
        allCustomFilters: this.customDashboardsList[index].components
          .filter((e) => e.type == "CUSTOM_FILTER")
          .map((e) => {
            e.value = "";
            e.selected_filter = "";
            return e;
          }),
        allMulCustomFilters: this.customDashboardsList[index].components
          .filter((e) => e.type == "MULTIPLE_CUSTOM_FILTER")
          .map((e) => {
            e.value = "";
            e.selected_filter = "";
            return e;
          }),
        // allIntegrationFilters: this.customDashboardsList[
        //   index
        // ].components.filter((e) => e.type == "INTEGRATION_FILTER"),
        keyValue: (Math.random() + 1).toString(36).substring(7),
        allowUserChangeOrder:
          this.customDashboardsList[index].allow_user_change_order,
      };
      const allEntities = [
        ...this.activeCustomDashboard.allTableComponents.flatMap(
          (e) => e.entity_id
        ),
        ...this.activeCustomDashboard.allStatComponents.flatMap(
          (e) => e.entity_id
        ),
        ...this.activeCustomDashboard.allCustomFilters.flatMap(
          (e) => e.entity_id
        ),
      ];
      this.activeCustomDashboard.allCustomFilters =
        this.activeCustomDashboard.allCustomFilters.filter(
          (e) => allEntities.indexOf(e.entity_id) !== -1
        );
      this.activeCustomDashboard.allTableComponents =
        this.activeCustomDashboard.allTableComponents.map((e) => {
          e.keyValue = (Math.random() + 1).toString(36).substring(7);
          return e;
        });
      this.activeCustomDashboard.allStatComponents =
        this.activeCustomDashboard.allStatComponents.map((e) => {
          // e.keyValue = (Math.random() + 1).toString(36).substring(7);
          return e;
        });
      this.activeCustomDashboard.allCustomFilters =
        this.activeCustomDashboard.allCustomFilters.map((e) => {
          e.keyValue = (Math.random() + 1).toString(36).substring(7);
          return e;
        });
      this.activeCustomDashboard.allTableComponents.forEach((el, i) => {
        if (el.is_expand) {
          this.expendedIndexs.push(i);
        }
      });
      this.activeCustomDashboardId = this.customDashboardsList[index]._id;
    },
    isSelectedStats(key) {
      if (key === this.selecetedDocStatus) {
        return true;
      } else {
        return false;
      }
    },
    getSelecetedDocumentStatus(stat) {
      this.selecetedDocStatus = stat.slug;
      this.selectedDocTitle = stat.title;
    },
    goToCreateDocument() {
      this.$router.push({ name: "upload-documents" });
    },
    async getDashboardStats() {
      this.loading = true;
      this.statsLoading = true;
      this.dataChecked = true;
      let params = {};
      if (this.$router?.currentRoute?.query?.type) {
        this.selectedRoute = this.$router.currentRoute.query.type;
      }
      await Promise.all([
        this.fetchNotifications(),
        this.fetchUserDashboardStatics(),
        this.fetchAllDocumentsDashboardStats(),
      ]);
      params.company_id = this.getActiveWorkspace?.company_id;
      await this.$store.dispatch("documents/fetchAllCompanyUsers", params);
      this.setDashboardStaticsData();
      let companyUserName = " ";
      let fullName =
        this.getAuthenticatedUser.first_name +
        " " +
        this.getAuthenticatedUser.last_name;
      if (this.getAllCompanyUsers?.data) {
        let companyUsersData = this.getAllCompanyUsers.data;
        this.companyUsers = companyUsersData.filter((el) => {
          companyUserName = el.first_name + " " + el.last_name;
          this.loading = false;
          return companyUserName != fullName;
        });
      }
      this.statsLoading = false;
      this.loading = false;
    },
    setDashboardStaticsData() {
      if (
        this.getUserDashboardStastics?.data &&
        this.getAllDocumentsDashboardStats?.length
      ) {
        let data = this.getUserDashboardStastics.data;
        data.map((stat) => {
          this.getAllDocumentsDashboardStats.forEach((el) => {
            if (stat.key == el.key) {
              stat.count = el.count;
            }
          });
        });

        let activeDashboardStatics = data.filter((element) => {
          return element.status === true;
        });
        //logic for dashboard stats color change
        activeDashboardStatics.map((stat) => {
          this.DashboardStatsColor.forEach((color) => {
            if (color.key == stat.key) {
              stat.background_color = color.background_color;
              stat.active_color = color.active_color;
            }
          });
        });

        this.activeDashboardStatics = activeDashboardStatics;
        if (this.activeDashboardStatics.length) {
          if (this.selectedRoute) {
            let statRoute = this.activeDashboardStatics.find(
              (e) => e && e.slug == this.selectedRoute
            );
            if (statRoute) {
              this.getSelecetedDocumentStatus(statRoute);
            } else {
              this.getSelecetedDocumentStatus(this.activeDashboardStatics[0]);
            }
          } else {
            this.getSelecetedDocumentStatus(this.activeDashboardStatics[0]);
          }
        }
      }
    },

    goToDocView(document) {
      if (document) {
        if (
          document.document_status == "SENT" ||
          document.document_status == "COMPLETED"
        ) {
          this.$router.push({
            name: "employee-documents-custom-document",
            params: {
              employee_document_id: document._id,
            },
          });
        } else {
          this.$router.push({
            name: "employee-documents-custom-document-edit",
            params: {
              employee_document_id: document._id,
            },
          });
        }
      }
    },
    DateFilterUpdate() {
      this.dateFilter = true;
    },
    handleChildEvent(latestStats) {
      this.formattedStats = latestStats;
    },
    async updateCsutomDashboard(component) {
      try {
        if (component.type === "STAT") {
          let statComponets = this.activeCustomDashboard.allStatComponents;
          statComponets.forEach((stat, index) => {
            if (stat.keyValue === component.keyValue) {
              this.activeCustomDashboard.allStatComponents[index] = component;
            }
          });
        } else {
          let tableComponents = this.activeCustomDashboard.allTableComponents;
          tableComponents.forEach((comp, index) => {
            if (comp.keyValue === component.keyValue) {
              this.activeCustomDashboard.allTableComponents[index] = component;
            }
          });
        }
        let params = {
          name: this.activeCustomDashboard.name,
          components: [
            ...this.activeCustomDashboard.allStatComponents,
            ...this.activeCustomDashboard.allTableComponents,
            ...this.activeCustomDashboard.allCustomFilters,
            ...this.activeCustomDashboard.allMulCustomFilters,
          ],
          include_global_date_filter:
            this.activeCustomDashboard.includeGlobalDateFilter,
          date_value: this.activeCustomDashboard.date_value,
          Custom_date_value: this.activeCustomDashboard.Custom_date_value,
          allow_user_change_order:
            this.activeCustomDashboard.allowUserChangeOrder,
        };
        await this.$store.dispatch("customDashboard/updateDashboard", {
          id: this.activeCustomDashboardId,
          params: params,
        });
        if (this.getUpdateDashboardStatus) {
          if (this.getUpdateDashboardData?.data) {
            await updateCustomDashboardData(
              this.activeCustomDashboardId,
              this.getUpdateDashboardData.data
            );
          }
          this.$notify({
            title: "Success",
            message: "Dashboard updated successfully",
            type: "success",
          });
        } else {
          let msg =
            this.getUpdateDashboardError && this.getUpdateDashboardError.message
              ? this.getUpdateDashboardError.message
              : "Error while editing dashboard";
          this.$notify({
            title: "Error",
            message: msg,
            type: "error",
          });
        }
      } catch (err) {
        let msg = err.message ? err.message : "Error while updating dashboard";
        this.$notify({
          title: "Error",
          message: msg,
          type: "error",
        });
      }
    },
    setIntegrationTableInfo(component) {
      try {
        if (component) {
          this.integrationComponent = component;
        }
        return;
      } catch (err) {
        console.log(err);
      }
    },
    checkIntegrationDashboard() {
      let isIntegration = this.activeCustomDashboard?.allStatComponents.find(
        (item) => item.data_source === "INTEGRATIONS"
      );
      if (isIntegration) {
        return true;
      } else {
        return false;
      }
    },
    updateGlobalDateFilterValue(filter) {
      if (filter && filter.dateValue?.length) {
        this.globalDateValue = {
          startDate: moment(filter.dateValue[0].value[0]).format("YYYY-MM-DD"),
          endDate: moment(filter.dateValue[0].value[1]).format("YYYY-MM-DD"),
        };
      }
    },
  },
  beforeDestroy() {
    this.$store.commit("dashboard/setAllDocumentsDashboardStats", null, {
      root: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.dashboard-filters {
  height: 50px;
}
.icon-search {
  margin-top: 8px;
  position: absolute;
  right: 20px;
  width: 17%;
  transform: translateY(-50%);
}
.wrapper-grid {
  // display: grid !important;
  // // grid-template-columns: 1fr, 1fr !important;
  // grid-template-columns: 50% 50%;
  // column-gap: 10px;
  // row-gap: 1em;
  // padding: 25px;
}
// .el-card {
//   margin-top: 10% !important;
// }

.dashboard {
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: thin;
  background-color: white;
  padding-left: 15px;
  border-radius: 5px;

  @media (max-width: 500px) {
    margin-top: 20px;
  }

  .stats-container {
    // display: flex;
    gap: 30px;
    .card {
      margin-right: 5px;
      margin-bottom: 5px;
      width: 165px;
      height: 69px;
      border-radius: 10px;
      min-width: 170px;

      cursor: pointer;
      border-color: #e3eafd;
      &:hover {
        background-color: #f4f7ff;
      }
      &.active {
        background-color: #ebeffd;
        border-color: #d2dcfa;
      }
    }
    .latest-updates {
      font-size: 0.85em;
    }
  }
  .icon-block {
    display: flex;
    gap: 10px;
    .icon-file {
      max-width: 20px;
      display: inline;
    }
  }
  .search {
    border-radius: 5px;
    border: none !important;

    //box-shadow: 3px 3px 3px 0.5px rgba(0,0,0,0.1);
  }
  .vue-data-table-default {
    .title {
      letter-spacing: 0.019em;
    }
    .circle {
      position: relative;
      padding-left: 20px;
      text-transform: capitalize;
      font-size: 14px;
      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        height: 10px;
        width: 10px;
        display: inline-block;
        border-radius: 50%;
      }
      &.completed::after {
        background: #08ad36;
      }
      &.draft::after {
        background: #f19203;
      }
      &.sent::after {
        background: #2f80ed;
      }
      &.active::after {
        background: #635e5e;
      }
    }
  }

  .notification-box {
    .list-group-flush > .list-group-item {
      border-width: 0 0 1px;
    }
    .noNotifications {
      margin-left: 40%;
      display: grid;
      gap: 0px;
      .img-text {
        margin-left: 5px;
        color: #1f1f1f;
      }
    }
    .notifcation-item {
      cursor: pointer;
      padding: 0;
      display: grid;
      grid-template-columns: 2.75em 1fr;
      gap: 15px;
      padding: 10px 0;
      .item-avatar {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #f754a2;
        background-color: rgba(#f754a2, $alpha: 0.1);
        color: #f754a2;
      }
      .item-body {
        .name {
          margin-bottom: 0;
        }
      }
    }
  }
}
.Title_sty {
  font-size: 12px;
  margin-left: -20px;
  margin-top: -10px;
}
.imgstyle {
  width: 25%;
  margin-left: 100px;
  margin-top: -18px;
  @media (max-width: 399.98px) {
    width: 36px;
    margin-top: -9px;
  }
}
.number_sty {
  margin-top: -35px;
  margin-left: -8px;
}
.drag-grid {
  display: grid !important;
  grid-template-columns: 49% 49% !important;
  column-gap: 10px !important;
  row-gap: 1em !important;
  padding: 25px 10px 25px 0px;
}
.wrapper-grid {
  // display: grid !important;
  // grid-template-columns: 50% 50% !important;
  // column-gap: 10px !important;
  // row-gap: 1em !important;
  // padding: 25px;
}

::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  scrollbar-width: thin;
}

.hr {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.select-company_type {
  margin-top: -8px;
  width: 40%;
}
.dashboard-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  font-weight: 500;
  margin-bottom: 10px;
}

.dashboard-card {
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease, color 0.2s ease;
  text-align: center;
  background-color: var(--application-background-color);
  color: black;
  height: 5vh;
  display: flex;
  align-items: center;
}

.dashboard-card.active {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
  transform: scale(1.05);
}

.dashboard-card:hover {
  background-color: var(--primary-color);
  color: var(--primary-contrast-color);
  transform: scale(1.03);
}
</style>
